.home-container-div {
    width: 100vw;
}

main {
    font-family: 'Josefin Sans';
    padding-bottom: 5%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.title-statement {
    margin: 0;
    padding: 0;
    display: grid;
    place-self: center;
}

.title-statement h1 {
    color: rgb(102,140,105);
    text-align: center;
}

.text-box {
    background-color: #f6f0ed;
    box-shadow: 2px 3px 6px #6f4024;
    display: grid;
    place-items: center;
    margin: 6%;
    padding: 2%;
    font-size: 20px;
    color: #6f4024;
    line-height: 50px;
}

.home-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.home-image1 {
    margin-bottom: 10%;
}

@media (max-width:540px) {
    
    .playgardens-image > img {
        order: 1;
        margin: 0 auto;
        margin-bottom: 5%;
        width: 100%;
    }
}