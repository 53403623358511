
.overall-div {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-div {
    font-family: 'Josefin Sans';
    color: #6f4024;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5%;
}

.title {
    margin-bottom: 2%;
}

.playgardens-page-container {
    display: flex;
    flex-direction: column;
}

.first-container {
    display: flex;
    flex-direction: row;
}

.about-the-playgardens {
    line-height: 30px;
    display: grid;
    place-self: center;
    width: 35%;
    text-align: justify;
    margin-right: 8%;
}

.about-the-playgardens h4, .space-attributes h4, .guidelines h4 {
    font-size: 24px;
}
.second-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.about-the-playgardens {
    display: flex;
    flex-direction: row;
    display: grid;
    place-self: center;
    line-height: 30px;
}


.playgarden-img2 {
    display: grid;
    place-self: center;
    width: 45%;
    margin-right: 5%;
}

.second-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.space-attributes {
    line-height: 30px;
    margin-left: 10%;
    display: grid;
    place-self: center;
}

.playgarden-img1 {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    display: grid;
    place-self: center;
    margin-top: 8%;
}

.third-container {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 5%;
}

.guidelines {
    display: grid;
    place-self: center;
}

.last-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.playgarden-img3 {
    display: grid;
    place-self: center;
}

.playgarden-img4 {
    display: grid;
    place-self: center;
}



@media (max-width: 540px) {
    .container-div {
        margin: 1%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .first-container, .second-container, .third-container, .last-container {
        display: flex;
        flex-direction: column;
    }
    .about-the-playgardens {
        width: 40%;
        display: grid;
        place-self: center;
    }
    .playgardens-container {
        width: 100%;
    }

}