

footer {
    font-family: 'Josefin Sans';
    background-color: #f6f0ed;
    width: 100vw;
    height: 40vh;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.menu-items {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 5%;
    margin-bottom: 2%;
}

a {
    text-decoration: none;
}

h3 {
    color: rgb(102,140,105);
}

#line {
    width: 80%;
    height: 1px;
    background-color: #6f4024;
    text-align: center;
    padding: 0;
    margin-left: 10%;
    margin-bottom: 5%;
}

.social-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.fb-logo, .insta-logo {
    padding-right: 5%;
}

p {
    color: #6f4024;
}

@media (max-width: 540px) {
    .menu-items {
        padding-right: 3%;
    }
}