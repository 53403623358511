.carousel {
    width: 90%;
    height: 700px;
    margin-top: 3%;
    margin-bottom: 6%;
}

.carouselInner {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: 'no-repeat';
    background-size: 'cover';
    display: flex;
}

.carouselInner .left {
    flex: 5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
}

.carouselInner .right {
    flex: 5%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
}

.carouselInner .center {
    flex: 80%;
    height: 100%;
    display: grid;
    place-items: center;
}

.carouselInner h1 {
    color: black;
    font-size: 40px;
}